<template>
  <div id="app">
    <!-- <keep-alive ref="keepAlive">
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" /> -->
    <!-- <keep-alive ref="keepAlive">
      <router-view></router-view>
    </keep-alive> -->
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  watch: {
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {
    
  },
};
</script>
<style lang="scss">
#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
</style>
