<template>
  <div class="audio-player">
    <audio
      class="origin-audio"
      ref="audioPlayer"
      :src="audioSrc"
      controls
      :autoplay="autoplay"
      @play="listenAudioPlay"
      @pause="listenAudioPause"
      @ratechange="listenAudioRatechange"
    ></audio>
    <div class="controls">
      <div class="c-left">
        <div class="btn-item" @click="handleAudioPlay" v-show="curIsPause">
          <span class="iconfont-com icon-common-bofang1" title="点击播放"></span>
        </div>
        <div class="btn-item" @click="handleAudioPause" v-show="!curIsPause">
          <span class="iconfont-com icon-common-zanting1" title="点击暂停"></span>
        </div>
      </div>
      <div class="c-time">
        <span>{{ curFormatTime }}</span>
        <span class="c-time-line">/</span>
        <span>{{ totalFormatTime }}</span>
      </div>
      <div
        ref="progressBar"
        class="progress-bar"
        draggable="false"
        ondragstart="return false;"
        ondrop="return false;"
        @mousedown="handleProgressMousedown"
        @mousemove="handleProgressMousemove"
        @mouseup="handleProgressMouseup"
        @mouseleave="handleProgressMouseleave"
        @click="handleSeekTo"
      >
        <div
          class="progress"
          :style="{ width: `${(currentTime / duration) * 100}%` }"
        ></div>
        <div class="thumb" :style="{ left: `${(currentTime / duration) * 100}%` }"></div>
      </div>
      <div class="mute-area">
        <div class="btn-item" v-show="!isMute" @click="handleSetMute(true)">
          <span class="iconfont-com icon-common-24gl-volumeMiddle"></span>
        </div>
        <div class="btn-item" v-show="isMute" @click="handleSetMute(false)">
          <span class="iconfont-com icon-common-24gl-volumeDisable"></span>
        </div>
        <div class="volume-set">
          <div
            ref="volumeProgressBar"
            class="volume-progress-bar"
            draggable="false"
            ondragstart="return false;"
            ondrop="return false;"
            @mousedown="handleVolumeProgressMousedown"
            @mousemove="handleVolumeProgressMousemove"
            @mouseup="handleVolumeProgressMouseup"
            @mouseleave="handleVolumeProgressMouseleave"
            :title="`当前音量${parseInt(curVolume * 100)}%`"
          >
            <div class="volume-progress" :style="{ height: `${curVolume * 100}%` }"></div>
            <div class="volume-thumb" :style="{ top: `${curVolume * 100}%` }"></div>
          </div>
        </div>
      </div>
      <div class="more-area" @click.stop="">
        <div class="btn-item" @click="handleClickMore">
          <span class="iconfont-com icon-common-androidgengduo"></span>
        </div>
        <div class="more-box" v-show="isShowMoreBox">
          <div class="more-item" v-show="isShowDown" @click="handleDownAudio">
            <span class="iconfont-com icon-common-xiazai"></span>
            <span class="btn-txt">下载</span>
          </div>
          <div class="more-item" v-show="isShowRateSet" @click="handleClickRateSet()">
            <span class="iconfont-com icon-common-bofangsudu1"></span>
            <span class="btn-txt">播放速度</span>
          </div>
        </div>
        <div class="playback-rate-box" v-show="isShowRateSetBox">
          <div
            class="rate-item"
            v-for="rate in playbackRates"
            :key="rate"
            @click="setPlaybackRate(rate)"
            :class="{ active: rate === playbackRate }"
          >
            {{ rate }}x
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDuration, downloadFile } from "@/utils/tool";
export default {
  props: {
    audioSrc: {
      type: String,
      required: true,
    },
    audioName: {
      type: String,
      required: false,
    },
    autoplay: {
      type: Boolean,
      require: false,
      default: true,
    },
    isShowDown: {
      type: Boolean,
      require: false,
      default: true,
    },
    isShowRateSet: {
      type: Boolean,
      require: false,
      default: true,
    },
  },
  data() {
    return {
      currentTime: 0,
      duration: 0,
      playbackRate: 1.0,
      playbackRates: [0.5, 0.75, 1.0, 1.5, 2.0],
      isDragging: false,
      curIsPause: true,
      isMouseDownPlay: false, // 鼠标按下时是否是播放状态
      totalFormatTime: "00:00",
      curFormatTime: "00:00",
      isShowRateSetBox: false,
      isMute: false,
      curVolume: 1,
      isVolumeDragging: false,
      isShowMoreBox: false,
    };
  },
  watch: {
    curIsPause() {
      if(this.curIsPause){
        this.$emit("pause")
      }else{
        this.$emit("play")
      }
    },
  },
  methods: {
    handleProgressMousedown(event) {
      console.log("mousedown");
      if (this.curIsPause) {
        this.isMouseDownPlay = false;
      } else if (!this.curIsPause) {
        this.isMouseDownPlay = true;
        this.handleAudioPause();
      }
      this.isDragging = true;
      this.handleSeekTo(event);
    },
    handleVolumeProgressMousedown(event) {
      console.log("volume mousedown");
      this.isVolumeDragging = true;
      this.handleVolumeSeekTo(event);
    },
    handleProgressMousemove(event) {
      if (this.isDragging) {
        console.log("mousemove");
        this.handleSeekTo(event);
      }
    },
    handleVolumeProgressMousemove(event) {
      if (this.isVolumeDragging) {
        console.log("volume mousemove");
        this.handleVolumeSeekTo(event);
      }
    },
    handleProgressMouseup() {
      console.log("mouseup");
      this.isDragging = false;
      if (this.isMouseDownPlay) {
        this.handleAudioPlay();
      }
    },
    handleVolumeProgressMouseup() {
      console.log("volume mouseup");
      this.isVolumeDragging = false;
    },
    handleProgressMouseleave() {
      console.log("mouseleave");
      this.isDragging = false;
      if (this.isMouseDownPlay) {
        this.handleAudioPlay();
      }
    },
    handleVolumeProgressMouseleave() {
      console.log("volume mouseleave");
      this.isVolumeDragging = false;
    },
    handleSeekTo(event) {
      console.log("click");
      const rect = this.$refs.progressBar.getBoundingClientRect();
      let x = event.clientX - rect.left;
      if (x < 0) {
        x = 0;
      }
      if (x > rect.width) {
        x = rect.width;
      }
      const newTime = (x / rect.width) * this.duration;
      /* console.log("rect: ", rect);
      console.log("x, rect.width: ", x, rect.width);
      console.log("this.duration, newTime: ", this.duration, newTime); */
      this.currentTime = newTime;
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.currentTime = newTime;
        this.curFormatTime = formatDuration(newTime * 1000);
      }
    },
    handleVolumeSeekTo(event) {
      const rect = this.$refs.volumeProgressBar.getBoundingClientRect();
      let t = event.clientY - rect.top;
      if (t < 0) {
        t = 0;
      }
      if (t > rect.height) {
        t = rect.height;
      }
      const curVolume = t / rect.height;
      /* console.log("rect: ", rect);
      console.log("x, rect.width: ", x, rect.width);
      console.log("this.duration, newTime: ", this.duration, newTime); */
      this.curVolume = curVolume;
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.volume = curVolume;
      }
    },
    // 下载音频
    handleDownAudio() {
      console.log("handleDownAudio");
      downloadFile(
        this.audioSrc,
        this.audioName ? audioName : `${new Date().getTime()}.mp3`
      );
    },
    /* 处理播放器播放 */
    handleAudioPlay() {
      this.$refs.audioPlayer.play();
    },
    /* 处理播放器暂停 */
    handleAudioPause() {
      this.$refs.audioPlayer.pause();
    },
    /* 播放器开始播放 */
    listenAudioPlay() {
      console.log("handleAudioPlay");
      this.curIsPause = false;
    },
    /* 播放器暂停播放 */
    listenAudioPause() {
      console.log("handleAudioPause");
      this.curIsPause = true;
    },
    /* 监听播放速度变化 */
    listenAudioRatechange() {
      console.log("listenAudioRatechange");
    },
    /* 点击设置播放速率 */
    handleClickRateSet() {
      this.isShowMoreBox = false;
      this.isShowRateSetBox = true;
    },
    /* 设置了播放速率 */
    setPlaybackRate(rate) {
      this.playbackRate = rate;
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.playbackRate = parseFloat(rate);
      }
      this.isShowRateSetBox = false;
    },
    /* 处理是否静音 */
    handleSetMute(isMute) {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.muted = isMute;
        this.isMute = isMute;
      }
    },
    /* 点击了更多 */
    handleClickMore() {
      this.isShowRateSetBox = false;
      this.isShowMoreBox = true;
    },
  },
  mounted() {
    document.addEventListener("click", () => {
      this.isShowRateSetBox = false;
      this.isShowMoreBox = false;
    });
    this.$refs.audioPlayer.addEventListener("loadedmetadata", () => {
      this.duration = this.$refs.audioPlayer.duration;
      this.curVolume = this.$refs.audioPlayer.volume;
      console.log("this.curVolume: ", this.curVolume);
      this.totalFormatTime = formatDuration(this.duration * 1000);
    });

    this.$refs.audioPlayer.addEventListener("timeupdate", () => {
      if (!this.isDragging) {
        this.currentTime = this.$refs.audioPlayer.currentTime;
        //console.log("this.currentTime: ", this.currentTime)
        this.curFormatTime = formatDuration(this.currentTime * 1000);
      }
    });

    this.$refs.audioPlayer.addEventListener("volumechange", () => {
      console.log("volumechange: ", this.$refs.audioPlayer.volume);
      this.curVolume = this.$refs.audioPlayer.volume;
      this.isMute = this.$refs.audioPlayer.muted;
    });
  },
  watch: {
    /* currentTime(newVal) {
      if (this.$refs.audioPlayer && newVal !== this.$refs.audioPlayer.currentTime) {
        this.$refs.audioPlayer.currentTime = newVal;
      }
    }, */
  },
};
</script>

<style scoped lang="scss">
.audio-player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  background: #e4e7ed;
  position: relative;
  border-radius: 60px;
  .origin-audio {
    position: absolute;
    top: -60px;
    display: none;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-radius: 60px;
  width: 100%;

  .btn-item {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 30px;
    text-align: center;
    line-height: 30px;
    &:hover{
        background: #C0C4CC;
    }
    span {
      font-size: 20px;
    }
  }
  .c-left {
    margin-right: 6px;
  }
  .c-time {
    .c-time-line {
      margin: 0 2px;
    }
  }
  .progress-bar {
    position: relative;
    flex: 1;
    height: 6px;
    background-color: #606266;
    cursor: pointer;
    margin: 0 10px;
    border-radius: 5px;
    .progress {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: #409EFF;
      width: 0;
      transition: width 0.3s ease;
      border-radius: 5px;
    }
    .thumb {
      position: absolute;
      top: -3px;
      left: 0;
      width: 12px;
      height: 12px;
      background-color: #fff;
      border: 2px solid #409EFF;
      border-radius: 50%;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      transform: translateX(-50%);
      transition: width 0.3s ease;
      z-index: 1;
      cursor: pointer;
    }
  }

  .more-area {
    position: relative;

    .more-box {
      position: absolute;
      padding: 6px;
      z-index: 10;
      left: -120px;
      top: -34px;
      width: 120px;
      background: #ffffff;
      border: 1px solid #ebeef5;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      .more-item {
        line-height: 40px;
        padding: 0 10px;
        cursor: pointer;
        .iconfont-com{
            margin-right: 6px;
            position: relative;
            top: 1px;
        }
        &:hover {
          background: #409EFF;
          color: #ffffff;
        }
      }
    }

    .playback-rate-box {
      position: absolute;
      background: #ffffff;
      z-index: 10;
      border: 1px solid #c0c4cc;
      width: 80px;
      top: 50%;
      transform: translateY(-50%) translateX(-100%);
      padding: 6px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      .rate-item {
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 5px;
        text-align: center;
      }
      .rate-item.active {
        background-color: #409EFF;
        color: #fff;
      }

      .rate-item:hover {
        background-color: #909399;
        color: #fff;
      }
    }
  }

  .mute-area {
    position: relative;
    &:hover {
      .volume-set {
        display: flex;
      }
    }
    .volume-set {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      z-index: 10;
      height: 100px;
      display: none;
      align-items: center;
      justify-content: center;
      width: 50px;
      background: #e4e7ed;
      border: 1px solid #c0c4cc;
      padding: 10px 0;
      border-radius: 4px;
      .volume-progress-bar {
        position: relative;
        width: 6px;
        height: 100%;
        background-color: #606266;
        cursor: pointer;
        margin: 0 10px;
        border-radius: 5px;
        .volume-progress {
          position: absolute;
          top: 0;
          left: 0;
          height: 10;
          background-color: #409EFF;
          width: 100%;
          transition: width 0.3s ease;
          border-radius: 5px;
        }
        .volume-thumb {
          position: absolute;
          top: 0px;
          left: 3px;
          width: 12px;
          height: 12px;
          background-color: #fff;
          border: 2px solid #409EFF;
          border-radius: 50%;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
          transform: translateX(-50%) translateY(-6px);
          transition: width 0.3s ease;
          z-index: 1;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
