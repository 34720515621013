import JSEncrypt from 'jsencrypt/bin/jsencrypt'

// 密钥对生成 http://web.chacuo.net/netrsakeypair
const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCeRcbVvvAN61k7RZBTOcbNE9ewfbEvVc69b8TKMsjHyfMAzLsMk08tLfwAJoT9kYfiT4a7SRs7Hke+2fiNd767rZtMXOTBr+v1k7uzzhp9pG6CPrmRs8Pe+tp4gA6Al3eE5uMMtM1odWuZNdBZz9qUX1TIty4mn+V4R3lXVf/SkQIDAQAB";

// const privateKey = 'MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAI2StBvPD38luEzM7CJd/G/JgGVlxI+/nUkZzKEHpqcKQbcC7ElmjGOyeb6N1FJpgKHDOkQFtfY+9CfZWF4EKLWHkhlsJNikvWb+8Zifdgn1UclFrC/zzkXqd1Ns8vYgvsCKfUm2OXxOudtoJMZq9nhvBOLE9NOkYO+jgWX3NCoDAgMBAAECgYBAtS2KSl1QZaqzu7QwTxn69DQx5E5vz4px549eiLM0xRzdK8JZGDyRsvkvTf8ePeeTyDpA7gVJzGNnNVHNAwShqfli5QzE69bPcTkYVBSef8lf+9+5h60ebyvB8Q7WCboUXYXrZ3KmBvN4GQ8Sm7KRSPgYU5LBb3SGnPoM8TRBdQJBAMTdnbAn8tEcjQADyqUQu5vv4Su2wLDdZfZiHCR3BgQDqW/1Bsq+Tf3DfPdZjk9bBhkuSYcVwBpvuC4iBS3ehF0CQQC4GT/imlit7btK/8oy+y+fKxWJII0u03DAN9Gl3OP8CBzqC/THJoI7QlP1VSRijGsJVTa1wywvProHXP6NnIHfAkEAvy6tfa4+DD/a2qHUAVtvpq9dkBNJrDlGVPDQfcUtDWb0/GoLb4dCvGdFi/znzD8MfBXMiChiSAoo5nxZZUNMbQJAUYlNVC2MDSx3gVYc1cuOYe6RETTLkuuuylNulQlqKBvnwnkdJn8Vuu07hjL5dHRAi9ej7YjNrvcXKV+0lj+0/QJAVFLiFaxT9DMtsFTjDvhDJ70n2+bHO1TV1MbHKYCjxzECrZ+sbtHtgzo7GhXaiB9eri+D3Dpz9iQni205HdfIuA=='

// 公钥加密
export function pubEncrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}

// 私钥解密
/* export function priDecrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey)
  return encryptor.decrypt(txt)
} */

// 私钥加密
/* export function priEncrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPrivateKey(privateKey); // 设置私钥
  return encryptor.encrypt(txt); // 私钥加密
} */

// 公钥解密
export function pubDecrypt(txt) {
  const decryptor = new JSEncrypt()
  decryptor.setPublicKey(publicKey);
  return decryptor.decrypt(encryptedData)
}

